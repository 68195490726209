<!-- =========================================================================================
    File Name: DragAndDropMultipleLists.vue
    Description: Drag and Drop to multiple lists
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <vx-card>
        <div class="vx-row">
            <div class="vx-col w-full md:w-1/4" >
                <div class="flex items-end mb-5">
                    <feather-icon icon="InfoIcon" class="mr-2" svgClasses="w-6 h-6" />
                    <h5 class="leading-none font-medium">{{ $t('menuCamera.infoGroup') }}</h5>
                </div>
                <div class="vx-row">
                    <div class="vx-col w-full md:w-full mt-2 mr-auto ml-auto">
                        <label class="vs-input--label">{{ $t('menuCamera.branch') }}</label>
                        <span class="text-danger">*</span>
                        <v-select class="" v-model="branchModel" :clearable="false" :options="branchOptions"
                                  data-vv-validate-on="blur" :disabled="isUpdate" v-validate="'required'" name="branch">
                            <span slot="no-options"> {{$t('menuOrganization.emptyList')}} </span>
                        </v-select>
                        <span class="text-danger text-sm">{{ errors.first('branch') }}</span>
                    </div>

                </div>
                <div class="vx-row">
                    <div class="vx-col w-full md:w-full mt-2 ml-auto mr-auto">
                        <label class="vs-input--label">{{ $t('menuCamera.groupName') }}</label>
                        <span class="text-danger">*</span>
                        <vs-input class="w-full"
                                  v-model="cameraGroupInfo.cameraGroupName"
                                  v-validate="'required'" name="cameraGroupName"/>
                        <span class="text-danger text-sm">{{ errors.first('cameraGroupName') }}</span>
                    </div>
                </div>
                <br>
            </div>
            <div class="vx-col w-full md:w-3/4">
                <div class="flex items-end mb-5">
                    <feather-icon icon="SettingsIcon" class="mr-2" svgClasses="w-6 h-6" />
                    <h5 class="leading-none font-medium">{{ $t('menuCamera.settingGroupCamera') }}</h5>
                </div>
                <div class="vx-row col-md-auto">
                    <div class="vx-col md:w-1/2 text-center">

                        <h6  > {{$t('menuCamera.listCamera')}}
                        </h6>

                    </div>
                    <div class="vx-col md:w-1/2 text-center">

                        <h6> {{$t('menuCamera.addedCamera')}}
                        </h6>

                    </div>
                </div>
                <div class="vx-row mt-2 ml-0 mb-2">
                    <v-multiselect-listbox :options="renderlistcamera"
                                           v-model="selectedStates"
                                           :reduce-display-property="(option) => option.cameraName"
                                           :reduce-value-property="(option) => option.value"
                                           :no-options-found-text="$t('menuCamera.notFoundCameraMl')"
                                           :no-selected-options-found-text="$t('menuCamera.notFoundCameraMl')"
                                           :no-options-text="$t('menuCamera.listCameraMl')"
                                           :selected-no-options-text="$t('menuCamera.listCameraMlTo')"
                                           :search-options-placeholder="$t('menuCamera.searchMl')"
                                           :selected-options-placeholder="$t('menuCamera.searchMl')"
                                           search-input-class="custom-input-class"
                                           highlight-diff
                                           :show-select-all-buttons="true"

                    >
                    </v-multiselect-listbox>
                </div>
            </div>
        </div>
        <!-- Save & Reset Button -->
        <div class="vx-row">
            <div class="vx-col w-full">
                <div class="flex flex-wrap items-center justify-end">
                    <vs-button class="ml-auto mt-2" @click="saveChanges" :disabled="!validateForm">{{
                            `${isUpdate ?
                                $t('button.update') : $t('button.create')}`
                        }}
                    </vs-button>
                    <vs-button class="ml-4 mt-2 text-size" type="border" @click="cameraList">
                        {{ $t('button.close') }}
                    </vs-button>
                </div>
            </div>
        </div>
    </vx-card>

</template>


<script>
import vSelect from 'vue-select'
import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'
import vMultiselectListbox from 'vue-multiselect-listbox'
import 'vue-multiselect-listbox/dist/vue-multi-select-listbox.css'


export default {
    components: {
        draggable,
        Prism,
        vSelect,
        vMultiselectListbox
    },
    data() {
        return {
            isUpdate: false,
            gridApi: null,
            branchId: null,
            branchOptions: [],
            cameraGroupInfo: {},
            renderlistcamera: [],
            addlistcamera: [],
            organizationBranchId: null,
            selectedStates: []
        }
    },
    async created() {
        let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]');
        this.branchOptions = branchList.map(x => {
            return {label: x.organizationBranchName, value: x.id}
        });
        this.branchId = this.$route.query.branch_id;
        this.cameraGroupInfo = {
            cameraGroupName: null,
            organizationBranchId: this.branchOptions.find(x => x.value.toString() === this.branchId) ? this.branchId : this.branchOptions[0].value
        }
        await this.getCamera();
        let id = this.$route.query.id;
        if (id) {
            this.$vs.loading();
            this.$crm.get(`/camera-group/find-by-id/` + id)
                .then((response) => {
                    this.$vs.loading.close();
                    this.addlistcamera = response.data;
                    if (response.data) {
                        this.addlistcamera = response.data.cameras.map(item => {
                            this.selectedStates.push(item.id)
                            return {value: item.id, cameraName: item.cameraName}
                        });
                    }
                    this.renderlistcamera.push( ...this.addlistcamera)
                    this.cameraGroupInfo = response.data;
                    if (response.data) {
                        this.isUpdate = true;
                        this.cameraGroupInfo = response.data;
                    }
                })
                .catch(() => {
                    this.$vs.loading.close();
                    this.cameraGroupInfo = {
                        organizationBranchId: this.branchOptions.find(x => x.value.toString() === this.branchId) ? this.branchId : this.branchOptions[0].value
                    }
                });
        }
    },
    computed: {
        branchModel: {
            get() {
                return {
                    label: this.getLabelString(this.branchOptions.find(x => x.value == this.cameraGroupInfo.organizationBranchId)),
                    value: this.cameraGroupInfo.organizationBranchId
                }
            },
            set(obj) {
                this.cameraGroupInfo.organizationBranchId = obj.value;
                this.getCamera();
            }
        },
        validateForm() {
            return !this.errors.any();
        }
    },
    methods: {
        getLabelString(object) {
            return (object && object.label) || this.$t('textCamera.undefined');
        },
        getCamera() {
            this.renderlistcamera = []
            this.selectedStates = []
            return new Promise(resolve => {
                let url = `/camera/find-not-in-group/${this.cameraGroupInfo.organizationBranchId}`;
                this.$vs.loading();
                this.$crm.get(url).then((response) => {
                    if (response.data.length > 0) {
                        this.renderlistcamera = response.data.map(item => {
                            return {value: item.id, cameraName: item.cameraName}
                        });
                    }
                    this.$vs.loading.close();
                    resolve(true);
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                })
            })
        },
        async saveChanges(evt) {
            evt.preventDefault();
            try {
                if (!this.validateForm) return;
                const result = await this.$validator.validateAll();
                if (!result) return;
                this.cameraGroupInfo.cameras = this.selectedStates.map(camera => camera);
                let url = this.isUpdate ? `/camera-group/update/${this.cameraGroupInfo.id}` : `/camera-group/create`,
                    data = this.cameraGroupInfo;
                this.$vs.loading();
                this.$crm.post(url, data).then(() => {
                    this.$vs.notify({
                        color: 'success',
                        text: `${this.isUpdate ? this.$t('button.update') : this.$t('button.add')}` + this.$t('textCamera.cameraGroupSuccess'),
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                    });
                    this.$vs.loading.close();
                    this.cameraList();
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            } catch (ex) {
                console.log(ex);
            }
        },
        cameraList() {
            this.$router.push(`/user/manage-camera-group${this.branchId ? `?branch_id=${this.branchId}` : ''}`).catch(() => {
            })
        }
    },
}
</script>
<style scoped lang="scss">
.fsz {
    font-size: 18px;
}

</style>
